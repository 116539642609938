import { Pipe, PipeTransform } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { LicenseService } from './../../core/services/license.service';
import { License } from '../models/license';

@Pipe({
	name: 'getLicense'
})
export class GetLicensePipe implements PipeTransform {

	license: License;
	cachedRef: any;

	constructor(private licenseService: LicenseService) { }

	transform(ref: DocumentReference<License>, ...args: unknown[]): unknown {
		if (ref && ref !== this.cachedRef) {
			this.cachedRef = ref;
			this.licenseService.getLicense(ref.id).subscribe(license => {
				this.license = license;
			});
		}
		return ref;
	}

}
