import { Action, DocumentChangeAction, DocumentReference, DocumentSnapshot } from "@angular/fire/firestore";


export function convertSnaps<T>(snaps: DocumentChangeAction<unknown>[]) {
	return <T[]>snaps.map(snap => convertDocumentChangeAction<T>(snap))
}

export function convertDocumentChangeAction<T>(snap: DocumentChangeAction<unknown>) {
	return <T>{
		id: snap.payload.doc.id,
		...snap.payload.doc.data() as T
	};
}

export function convertDocumentSnapshot<T>(snap: Action<DocumentSnapshot<unknown>>) {
	return <T>{
		id: snap.payload.id,
		...snap.payload.data() as T
	};
}

export function convertRefs<T>(refs: DocumentReference<T>[], objects: T[]) {
	return refs.map(ref => convertRef(ref, objects)) as T[]
}

export function convertRef<T>(ref: DocumentReference<T>, objects:  T[] | any[]) {
	return objects.find(obj => obj.id === ref.id) as T;
}

