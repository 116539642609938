import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Pipe({
  name: 'downloadURL',
	pure: false
})
export class DownloadURLPipe implements PipeTransform {

	cachedDownloadRef: string;
	downloadURL: string = '/assets/img/user_placeholder.png';

	constructor(private storage: AngularFireStorage) { }

  transform(downloadRef: string, ...args: unknown[]): unknown {
		if(downloadRef && downloadRef !== this.cachedDownloadRef) {
			this.cachedDownloadRef = downloadRef;
			const ref = this.storage.ref(downloadRef);
			ref.getDownloadURL().subscribe(downloadURL => {
				if(downloadURL) {
					this.downloadURL = downloadURL;
				}
			});
		}
		return this.downloadURL;
  }

}
