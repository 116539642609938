import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DownloadURLPipe } from './pipes/download-url.pipe';
import { GetLicensePipe } from './pipes/get-license.pipe';

import { ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterTrainerPipe } from './pipes/filter-trainer.pipe';

import { GetTrainerPipe } from './pipes/get-trainer.pipe';
import { DesiredAppointmentPipe } from './pipes/desired-appointment.pipe';
import { TrainerPipe } from './pipes/trainer.pipe';


@NgModule({
	declarations: [
		// Pipes
		DownloadURLPipe,
		GetLicensePipe,
		GetTrainerPipe,
		DesiredAppointmentPipe,
		TrainerPipe,
		FilterTrainerPipe,
	],
	providers: [
		GetTrainerPipe,
		DatePipe,
	],
	imports: [
		CommonModule,
	],
	exports: [
		// Angular
		CommonModule,

		// Forms
		ReactiveFormsModule,

		// Flex
		FlexLayoutModule,

		// Material
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatTableModule,
		MatSelectModule,
		MatTabsModule,
		MatDialogModule,
		MatChipsModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatTooltipModule,

		// Pipes
		DownloadURLPipe,
		GetLicensePipe,
		GetTrainerPipe,
		DesiredAppointmentPipe,
		TrainerPipe,
		FilterTrainerPipe,
	]
})
export class SharedModule { }
