import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Trainer } from './../../shared/models/trainer';
import * as geofire from 'geofire-common';
import { Location } from './../../shared/models/location';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { convertSnaps, convertDocumentSnapshot } from './../utils/db-utils';

export const TRAINERS_PATH: string = 'trainers';

@Injectable({
	providedIn: 'root'
})
export class TrainerService {

	constructor(private _db: AngularFirestore, private _storage: AngularFireStorage) { }

	getTrainers() {
		return this._db.collection(TRAINERS_PATH, ref => ref.orderBy('firstName'))
			.snapshotChanges()
			.pipe(
				map(snaps => convertSnaps<Trainer>(snaps))
			);
	}

	getTrainer(id: string) {
		return this._db.collection(TRAINERS_PATH)
			.doc(id)
			.snapshotChanges()
			.pipe(
				map(snap => convertDocumentSnapshot<Trainer>(snap))
			);
	}

	addTrainer(trainer: Partial<Trainer>) {
		return this._db.collection(TRAINERS_PATH).add(trainer);
	};

	updateTrainer(id: string, trainer: Partial<Trainer>) {
		return this._db.collection(TRAINERS_PATH).doc(id).update(trainer);
	};

	deleteTrainer(id: string) {
		return this._db.collection(TRAINERS_PATH).doc(id).delete();
	}

	getTrainersByLocation(location: Location, radiusInM: number) {
		const center = [location.lat, location.lng];
		const bounds = geofire.geohashQueryBounds(center, radiusInM);

		const queries = [];
		for (const b of bounds) {
			const query = this._db.collection(TRAINERS_PATH, ref => ref
				.where('address.location.geohash', '>=', b[0])
				.where('address.location.geohash', '<=', b[1])
				.orderBy('address.location.geohash')
			);
			queries.push(query.snapshotChanges());
		}
		return zip(...queries)
			.pipe(
				map(x => x[0].concat(x[1])),
				map(snaps => convertSnaps<Trainer>(snaps))
			);
	}

	getTrainersByOnline() {
		return this._db.collection('trainers', ref => ref
			.where('videoTraining', '==', true)
		)
			.snapshotChanges()
			.pipe(
				map(snaps => convertSnaps<Trainer>(snaps))
			);
	}

	uploadFile(event: any, id: string) {
		const file = event.target.files[0];
		const filePath = 'users/' + id + '_' + file.name;
		const task = this._storage.upload(filePath, file);
		return task;
	}

	getRef(id: string) {
		return this._db.collection(TRAINERS_PATH).doc(id).ref;
	}
}
