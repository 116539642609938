import { Pipe, PipeTransform } from '@angular/core';
import { TrainerService } from './../../core/services/trainer.service';
import { Trainer } from '../models/trainer';
import { DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getTrainer',
	//pure: false
})
export class GetTrainerPipe implements PipeTransform {

	constructor(private trainerService: TrainerService) { }

	transform(ref: DocumentReference<Trainer>, ...args: unknown[]): Observable<Trainer> | null {
		if (ref) {
			return this.trainerService.getTrainer(ref.id);
		}
		return null;
	}

}
