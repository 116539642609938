<mat-toolbar color="primary">
	<div class="logo-wrapper">
		<a href="https://bodycontrol.io">
			<img src="assets/img/bodycontrol_logo_white.png" />
		</a>
	</div>
	<span class="toolbar-spacer"></span>
	<div fxHide.xs fxHide.sm fxHide.md>
		<a mat-button href="https://apps.apple.com/de/app/bodycontrol-training/id1568707792">DOWNLOAD ON IOS</a>
		<a mat-button href="https://play.google.com/store/apps/details?id=io.bodycontrol.app">DOWNLOAD ON ANDROID</a>
		<a mat-button href="" routerLink="/trainers" class="active">JETZT COACH KONTAKTIEREN</a>
	</div>
	<button mat-icon-button [matMenuTriggerFor]="menu" fxHide.lg fxHide.xl>
		<mat-icon>menu</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<a mat-menu-item href="https://apps.apple.com/de/app/bodycontrol-training/id1568707792">DOWNLOAD ON IOS</a>
		<a mat-menu-item href="https://play.google.com/store/apps/details?id=io.bodycontrol.app">DOWNLOAD ON ANDROID</a>
		<a mat-menu-item href="" routerLink="/trainers" class="active">JETZT COACH KONTAKTIEREN</a>
	</mat-menu>
</mat-toolbar>