import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { License } from 'src/app/shared/models/license';
import { map } from 'rxjs/operators';
import { convertSnaps, convertDocumentSnapshot } from './../utils/db-utils';

const LICENSES_PATH: string = 'licenses';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private _db: AngularFirestore) { }

	getLicenses() {
		return this._db.collection(LICENSES_PATH, ref => ref.orderBy('name'))
			.snapshotChanges()
			.pipe(
				map(snaps => convertSnaps<License>(snaps))
			);
	}

	getLicense(id: string) {
		return this._db.collection(LICENSES_PATH)
			.doc(id)
			.snapshotChanges()
			.pipe(
				map(snap => convertDocumentSnapshot<License>(snap))
			);
	}

	addLicense(license: Partial<License>) {
		return this._db.collection(LICENSES_PATH).add(license);
	};

	updateLicense(id: string, license: Partial<License>) {
		return this._db.collection(LICENSES_PATH).doc(id).update(license);
	};

	deleteLicense(id: string) {
		return this._db.collection(LICENSES_PATH).doc(id).delete();
	}

	getRef(id: string) {
		return this._db.collection(LICENSES_PATH).doc(id).ref;
	}

}
