import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerHeaderComponent } from './header/customer-header/customer-header.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
		// Components
		CustomerHeaderComponent,
	],
  imports: [
		// Angular
    CommonModule,

		// Shared
		SharedModule,
  ],
	exports: [
		// Components
		CustomerHeaderComponent,
	]
})
export class CoreModule { }
