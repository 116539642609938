import { Pipe, PipeTransform } from '@angular/core';
import { Trainer } from '../models/trainer';

@Pipe({
  name: 'filterTrainer'
})
export class FilterTrainerPipe implements PipeTransform {

	transform(trainers: Trainer[], search: { city?: string, onlineTrainer?: boolean, searchTerm?: string }): Trainer[] {
		if(search.city || search.onlineTrainer || search.searchTerm) {
			return trainers.filter((trainer => {
				return (!search.city || search.city === trainer.address?.city)
					&& (!search.onlineTrainer || search.onlineTrainer === trainer.videoTraining)
					&& (!search.searchTerm || search.searchTerm.length < 3 || this.filterText(trainer, search.searchTerm))
			}));
		}
		return trainers;
  }

	filterText(trainer: Trainer, searchTerm: string) {
		searchTerm = searchTerm.toLocaleLowerCase();
		return trainer.firstName?.toLocaleLowerCase().includes(searchTerm)
			|| trainer.lastName?.toLocaleLowerCase().includes(searchTerm)
			|| trainer.tags?.find(tag => tag.name?.toLowerCase().includes(searchTerm))
			|| trainer.licenses?.find(license => license.name?.toLowerCase().includes(searchTerm))
	}

}
